/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Essentals

*/

// import specific babel polyfills for ie11
import 'core-js/es/array';
import 'core-js/web/dom-collections';

import $ from 'jquery'; // eslint-disable-line


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

NPM Packages

*/

// eslint-disable-next-line
import 'lazysizes/plugins/print/ls.print'; // needs to be BEFORE the main lazysizes script
import 'lazysizes'; // eslint-disable-line

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

allink CORE (NPM package)

===

These modules provide core functionality.

Note: Should you need a customzied project specific version of one of
the following modules:

Head over to the 'allink-core-static' repo, comment out the module below,
download the latest version and place it in the ./modules folder.

*/

import 'allink-core-static/js/modules/ajax-form';
import 'allink-core-static/js/modules/ajax-load-plugins';
import 'allink-core-static/js/modules/bootstrap-select';
import 'allink-core-static/js/modules/btn-ajax-loader';
import 'allink-core-static/js/modules/datepicker';
import 'allink-core-static/js/modules/site-overlay';
import 'allink-core-static/js/modules/default-modal';
import 'allink-core-static/js/modules/djangocms-tweaks';
import 'allink-core-static/js/modules/expandable';
import 'allink-core-static/js/modules/feature-detection';
import 'allink-core-static/js/modules/form';
import 'allink-core-static/js/modules/form-modal';
import 'allink-core-static/js/modules/form-validation';
import 'allink-core-static/js/modules/full-height';
import 'allink-core-static/js/modules/image-modal';
import 'allink-core-static/js/modules/lazysizes';
import 'allink-core-static/js/modules/pagechooser';
import 'allink-core-static/js/modules/seo';
import 'allink-core-static/js/modules/smooth-scroll';
import 'allink-core-static/js/modules/softpage';
import 'allink-core-static/js/modules/table';
import 'allink-core-static/js/modules/tingle';
import 'allink-core-static/js/modules/video';
import 'allink-core-static/js/modules/viewport-dimensions';

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

Custom Scripts

*/

import './modules/ajax-load-items';
import './modules/header';
// import './modules/map';
import './modules/navigation';
import './modules/parallax';
import './modules/swiper';
import './modules/seo-accordion';
import './modules/browser-recognition';


$(() => {
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

    Initialize CMS-Plugins after Ajax-Request

    */

    if (typeof CMS !== 'undefined') {
        $(window).on('ajaxLoadItems:success', () => {
            CMS.Plugin._initializeTree(); // eslint-disable-line
        });
    }

});

/* ========== Rolex Cookie Consent ========== */
(function() {
    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    }

    function setCookie(name, value) {
        const date = new Date();
        date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));  // 30 days
        document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/; SameSite=Strict`;
    }

    if (!getCookie('rlx-consent')) {
        setCookie('rlx-consent', 'false');
    }

    $(document).on("click", "#CybotCookiebotDialogBodyButtonAccept", function(){
        setCookie('rlx-consent', 'true');
    });

    $(document).on("click", "#CybotCookiebotDialogBodyButtonDecline", function(){
        setCookie('rlx-consent', 'false');
    });
})();
